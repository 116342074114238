<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--内容-->
      <div class="content">
        <!--登录表单-->
        <div class="login">
          <h1>用户登录</h1>
          <el-form>
            <el-form-item label="邮箱">
              <el-input v-model="login_data.email" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="login_data.password" type="password" show-password/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="login_check">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import md5 from "js-md5";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {user_login} from "../api/api";
import {email_check, jump_page, success_hint, warning_hint} from "../tool/tools";

export default {
  name: "Login",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  data(){
    return {
      login_data: {
        email: null,
        password: null
      },
      // 验证码按钮
      verify_code_button: {
        text: "获取验证码",
        total_time: 60,
        is_click: false
      }
    }
  },
  methods: {
    user_login,
    jump_page,
    login_check(){
      // 判断邮箱是否输入
      if(this.login_data.email === null || this.login_data.email === ""){
        warning_hint("请输入邮箱");
        return false;
      }
      // 判断邮箱格式
      if(!email_check(this.login_data.email)){
        warning_hint("请输入正确的邮箱");
        return false;
      }
      // 判断是否输入密码
      if(this.login_data.password === null || this.login_data.password === ""){
        warning_hint("请输入密码");
        return false;
      }
      // 用户登录
      this.login();
    },
        login(){
        this.user_login({
        email: this.login_data.email,
        password: md5(this.login_data.password).toUpperCase()
      }).then(result => {
        let response = result.Data;
        success_hint("登录成功");
        sessionStorage.setItem("Token", response["Token"]);
        this.jump_page("/");
      });
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "../static/universal/index.css";
.login{
  width: 600px;
  background: white;
  margin: 20px auto;
  padding: 30px;
  border-radius: 8px;
}
</style>